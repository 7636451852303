import RestService from "@/services/Rest.service";
import RestUrls from "@/services/Rest.paths";
import {
  ProcesioEntity,
  ProcesioEntityType,
  Role,
  RoleType,
  UserPermissions,
  UserType,
  UserTypeName,
} from "./UserPermissions.model";
import { config } from "@/config";
import { store } from "@/store";

export default class UserPermissionsService {
  static baseUrl = config.api;

  static getProcesioEntityList() {
    return RestService.get<ProcesioEntity[]>({
      url: RestUrls.API.USER.PERMISSIONS.PROCESIO_ENTITY_LIST,
      isAuth: true,
      baseUrl: this.baseUrl,
      workspaced: false,
    });
  }

  static getRoleList() {
    return RestService.get<Role[]>({
      url: RestUrls.API.USER.PERMISSIONS.ROLE_LIST,
      isAuth: true,
      baseUrl: this.baseUrl,
      workspaced: false,
    });
  }

  static getUserTypeList() {
    return RestService.get<UserType[]>({
      url: RestUrls.API.USER.PERMISSIONS.USER_TYPE_LIST,
      isAuth: true,
      baseUrl: this.baseUrl,
      workspaced: false,
    });
  }

  // get currently logged in user permmissions by keycloak user id
  static getActiveUserPermissions(workspaceId: string | null) {
    return RestService.get<UserPermissions>({
      url: RestUrls.API.USER.PERMISSIONS.USER_PERMISSIONS(),
      isAuth: true,
      baseUrl: this.baseUrl,
      workspaced: true,
      header: {
        workspaceId: workspaceId || "",
      },
    });
  }

  static getUserPermissions(dbUserId: string) {
    return RestService.get<UserPermissions>({
      url: RestUrls.API.USER.PERMISSIONS.USER_PERMISSIONS(dbUserId),
      isAuth: true,
      baseUrl: this.baseUrl,
      workspaced: true,
    });
  }

  static saveUserPermissions(userId: string, data: UserPermissions) {
    return RestService.put({
      url: RestUrls.API.USER.PERMISSIONS.USER_PERMISSIONS(userId),
      isAuth: true,
      baseUrl: this.baseUrl,
      workspaced: true,
      data,
    });
  }

  static getDefaultUserPermissionsPerWorkspace(workspaceId: string) {
    return RestService.get<UserPermissions>({
      url: RestUrls.API.USER.PERMISSIONS.DEFAULT_USER_PERMISSIONS_PER_WORKSPACE(
        workspaceId
      ),
      isAuth: true,
      baseUrl: this.baseUrl,
      workspaced: false,
    });
  }
}
