<template>
  <pds-select
    class="pup-c-flow-select"
    :value="value"
    @update-input="$emit('update-input', $event)"
    @blur="$emit('blur')"
    :options="options"
    :label="label"
    :required="true"
    :tooltip="tooltip"
    :status="status"
    :disabled="disabled"
    searchable
    searchPlaceholder="Search by name"
  >
    <template v-slot:singleLabel="{ selectedOption, keyName }">
      <slot
        name="singleLabel"
        v-bind:selectedOption="selectedOption"
        v-bind:keyName="keyName"
      />
    </template>

    <template v-slot:placeholder="{ placeholder }">
      <slot name="placeholder" v-bind:placeholder="placeholder" />
    </template>
  </pds-select>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Model, Watch } from "vue-property-decorator";
import { PdsTypes, SelectComponent } from "@procesio/procesio-design-system";
import OrchestrationService, {
  Flow,
} from "@/services/crud/Orchestration.service";
import { ActionTypes as ProcessActionTypes } from "@/store/process/Process.actions";
import {
  GenericSetting,
  Node,
  ProcessInputOuputSetting,
  Setting,
  SettingType,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import { SUBPROCESS_SIDE_PANEL_SETTING } from "../../PropertiesPanel/Utils/Settings";
import { escapeString } from "@/utils/type/string";
import { mixins } from "vue-class-component";
import { Permissionable } from "@/mixins/Permissionable";

@Component({
  components: {
    "pds-select": SelectComponent,
  },
})
export default class FlowSelectComponent extends mixins(Permissionable) {
  @Model("update-input") value: unknown;

  @Prop() settings!: Setting;

  @Prop() label: string | undefined;

  @Prop() tooltip?: string;

  @Prop() parentFlow!: Flow | null;

  @Prop() status!: PdsTypes.InputStatus;

  @Prop() parent!: Node;

  @Prop({ default: false, type: Boolean }) disabled?: boolean;

  @Prop({ default: true, type: Boolean }) hasInternalValidation!: boolean;

  options: Array<{ value: string; name: string; isValid: boolean }> = [];

  get parentFlowId() {
    if (!this.parentFlow) {
      return null;
    }
    return this.parentFlow.id;
  }

  @Watch("value", { immediate: true })
  async onValueUpdate(id: string | null, oldId: string | undefined) {
    if (id && this.parent && this.parent.id) {
      if (oldId && id !== oldId) {
        this.resetMapping();
      }

      const response = await OrchestrationService[
        this.$permitted(
          this.ProcesioEntityTypes.ProcessDesigner,
          this.RoleTypes.Read
        )
          ? "loadFlow"
          : "loadFlowRestricted"
      ](id);

      if (response.content) {
        const flow = response.content.flow;

        await this.$store.dispatch(
          ProcessActionTypes.SET_EXTRA_VARIABLES_BY_KEY,
          {
            nodeId: this.parent.id,
            variables: flow.variables.map((variable) => ({
              ...variable,
              scopesId: [flow.id],
            })),
          }
        );

        if (
          !this.$permitted(
            this.ProcesioEntityTypes.ProcessDesigner,
            this.RoleTypes.Read
          )
        ) {
          this.options = [
            {
              value: flow.id,
              name: escapeString(flow.title),
              isValid: flow.isValid,
            },
          ];
        }
      }
    }
  }

  @Watch("status", { immediate: true })
  onStatusUpdate() {
    this.validate();
  }

  validate() {
    if (!this.hasInternalValidation || !this.value) {
      return;
    }

    const selectedProcess = this.options.find(
      (option) => option.value === this.value
    );

    const errorMessage = "Invalid process is not allowed.";
    let messages = [...(this.settings.status?.message || [])];
    const messageIndex = Array.isArray(messages)
      ? messages.indexOf(errorMessage)
      : -1;

    if (selectedProcess && !selectedProcess.isValid) {
      messageIndex === -1 && (messages = [errorMessage]);
    } else {
      messageIndex !== -1 && messages.splice(messageIndex, 1);
    }

    if (this.settings.status) {
      this.settings.status.message = messages;
    } else {
      this.settings.status = {
        type: PdsTypes.StatusType.ERROR,
        message: messages,
      };
    }
  }

  resetMapping() {
    if (!this.parent) {
      return;
    }

    const nodeConfig = this.parent.configuration;

    // reset subprocess values when subprocess id is changed
    const subprocessSetting = nodeConfig[0].settings.find(
      (s) => s.id === SUBPROCESS_SIDE_PANEL_SETTING
    ) as GenericSetting;
    if (subprocessSetting && subprocessSetting.value) {
      const input = subprocessSetting.value.find(
        (v: ProcessInputOuputSetting) => v.type === SettingType.PROCESS_INPUT
      )?.value;
      const output = subprocessSetting.value.find(
        (v: ProcessInputOuputSetting) => v.type === SettingType.PROCESS_OUTPUT
      )?.value;
      if (input && output) {
        [...input, ...output].forEach(
          (val: { id: number; subprocess: string; process: string }) =>
            (val.subprocess = "")
        );
      }
    }
  }

  async mounted() {
    if (
      this.$permitted(
        this.ProcesioEntityTypes.ProcessDesigner,
        this.RoleTypes.Read
      )
    ) {
      const response = await OrchestrationService.loadAllFlow();

      if (!response.content) {
        return;
      }

      this.options = (response.content.pageItems as Flow[])
        .filter((option: Flow) => option.id !== this.parentFlowId)
        .map((option: Flow) => ({
          value: option.id,
          name: escapeString(option.title),
          isValid: option.isValid,
        }));
    }
  }
}
</script>

<style lang="scss">
@import "./FlowSelect.component.scss";
</style>
