import { escapeString } from "@/utils/type/string";
import { FormState } from "./Form.store";

export default {
  id: (state: FormState) => state.id,
  name: (state: FormState) => (state.name ? escapeString(state.name) : ""),
  mode: (state: FormState) => state.mode,
  status: (state: FormState) => state.status,
  users: (state: FormState) => state.users,
  isActive: (state: FormState) => state.isActive,
  isSaved: (state: FormState) => state.isSaved,
  hideBrading: (state: FormState) => state.hideBrading,
  submitter: (state: FormState) => state.submitter,
};
